@use "../abstracts" as *;

.page-contact main section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 150px;

  .message {
    padding-bottom: 100px;
  }

  h1 {
    font-size: $font-size-medium;
  }

  .contact-text {
    width: 40%;

    > div {
      margin: 0 auto;
      width: fit-content;

      @include bp-medium {
        margin: initial;
      }
    }

    @include bp-medium {
      width: 100%;
      padding-bottom: 50px;
    }

    @include bp-small {
      padding-left: 0;
    }
  }

  .contact-form {
    background-color: #0040fe;
    padding: 50px;
    min-width: 50%;
    max-width: 60%;
    min-height: 420px;

    @include bp-medium {
      max-width: 100%;
    }

    @include bp-small {
      padding: 50px 25px;
    }
  }

  @include bp-medium {
    flex-direction: column;
  }
}
