@use "../abstracts" as *;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@function makelongshadow($secondary-color) {
  $val: 0px 0px $secondary-color;
  @for $i from 1 through 1500 {
    $val: #{$val}, #{-$i}px #{$i}px #{$secondary-color};
  }
  @return $val;
}

@mixin longshadow($secondary-color) {
  text-shadow: makelongshadow($secondary-color);
}

@keyframes down-to-center {
  from {
      margin-left: -100vw;
      margin-bottom: -100vw;
    }
  
    to {
      margin-left: 0;
      margin-bottom: 0;
    }
}

$font-family-primary: 'Nunito Sans', sans-serif;

.page-resume {
  overflow-x: hidden;
  background-color: $secondary-color;

  h1, h2, div, p, a {
    font-family: $font-family-primary;
    color: $quaternary-color;
  }

  a {
    font-weight: 800;
    transition: .5s;
    text-decoration: none;

    &:hover {
      color: #03fcfc;
    }
  }
  

  #root .App {
    max-width: unset;
  }
  
  .page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .page-1 {
    height: calc(100vh + 30px);
    width: 100%;
    text-align: center;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;

    svg {
      min-width: 1350px;
      width: 1900px;
      max-width: 100%;
      height: auto;
      animation: down-to-center 1s forwards;
    }
  }

  .line {
    width: 100%;
    margin-top: -15px;
  }
  
  .page-2 {
    max-width: 1200px;
    margin-top: 100px;
  
    .header {
      display: inline-block;

      h2 {
        font-size: 6rem;
        line-height: 5.2rem;
        color: #03fcfc;
        font-weight: 900;
        font-style: italic;
      }
  
      hr {
        margin-left: 50px;
        margin-top: 8px;
      }
    }

    hr {
      width: 100%;
      height: 15px;
      background-color: $quaternary-color;;
      border: none;
    }

    .border-rounded {
      border: 11px solid $quaternary-color;
      border-radius: 50%;
    }

    .born-in {
      display: flex;
      flex-direction: row;
    }
  
   .calendar {
      width: 250px;
      height: auto;
      margin-left: 150px;
    }

    .lives-in {
      width: 100%;

      .header {
        position: absolute;
        margin: 200px 0px 0px 250px;
        //margin: 18.5% 0 0 24%;
      }

      .content {
        position: absolute;
        margin: 370px 0 0 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 2.2rem;
        font-weight: 800;
      }

      .map {
        display: block;
        width: 1150px;
        height: auto;
        margin: 0 auto;
      }

      .map-pin {
        width: 50px;
        height: auto;
        margin-top: 7px;
      }
    }

    .experience {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      margin-top: 150px;


      .box {
        position: absolute;
      }
      
      .box,
      .checked {
        width: 150px;
        height: auto;
        margin-left: 275px;
        margin-top: 25px;
      }

      .invisible {
        display: none;
      }
      
      .fade {
        animation: boxChecked 1.2s ease-in alternate;
      }

      .check {
        overflow: hidden;
        position: absolute;
      }

      .width-0 {
        width: 0%;
      }

      @keyframes boxChecked {
        0% {
          width: 0%;
        }
      
        100% {
          width: 100%
        }
      }
    }

    .timeline {
      width: 100%;
      margin-top: 200px;

      .items {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        font-size: 1.8rem;
        font-weight: 800;
      }

      .item:nth-child(odd) {
          margin-top: -110px

      }
      .item:nth-child(even) {
          margin-top: -32px
      }

      .point {
        background-color: $quaternary-color;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 5px solid #013cfe;
      }
    }

    .apps-skills {
      display: flex;
      justify-content: space-around;
      margin-top: 150px;
      width: 100%;

      .content {
        margin-top: 75px;
        display: flex;
        flex-wrap: wrap;
        max-width: 425px;
        padding-left: 25px;
      }

      .apps .icon {
        width: 140px;
        height: auto;
        margin: 0 45px 45px 0px;
      }

      .container:nth-child(odd) .icon {
        float: right;
      }

      .conteiner:nth-child(even) .icon {
        float: left;
      }

      .skills {

        .icon {
          width: auto;
          height: 60px;
          margin: 0 20px 20px 0;
        }

        .javascript {
          width: 50px;
        }

        .jquery {
          width: 140px;
        }
      }
    }

    .projects {
      margin-top: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        max-width: 850px;
      }

      .project {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        flex: 0 0 50%;
        padding-top: 60px;

        .header {
          width: 100%;
          font-size: 1.8rem;
          font-weight: 800;
          padding-bottom: 10px;
        }

        .content {
          //display: grid;
          //grid-template-columns: 50% 50%;
          width: calc(100% - 9px);
          padding-left: 20px;
          font-size: 1.2rem;
          font-weight: 800;
        }
      }

      .project:nth-child(odd) {
        padding-right: 75px;
      }

      .project:nth-child(even) {
        padding-left: 75px;
      }

      .hr {
        background: #ffffff;
        width: 3px;
        margin-left: 6px;
      }
    }

    .contact {
      margin-top: 75px;

      .header {
        padding-left: 50px;
      }

      .content {
        padding-top: 50px;
        padding-bottom: 75px;  
      
      img {
        width: 30px;
        height: auto;
        vertical-align: middle;
        margin-right: 20px;
      }
    }

      .content p {
        text-align: center;
        padding-left: 50px;
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 800;
      }
    }
  }
}

