@use "../abstracts" as *;

form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div,
  span {
    margin-bottom: 10px;
  }

  > div {
    width: 100%;
  }

  h2 {
    color: $quaternary-color;
  }

  input,
  textarea {
    font-family: $font-family-primary;
    padding: 10px 15px;
    font-size: 1rem;
    @include borderBlack;
    border-width: 2px;

    &:focus-visible {
      outline: 1px solid $main-color;
    }
  }

  input,
  textarea {
    margin-bottom: 10px;
    width: 100%;
    color: $main-color;
  }

  textarea {
    min-height: 100px;
  }

  button {
    height: 100px;
    width: 100px;
    color: $quaternary-color;
    @include borderRounded;

    &:hover {
     color: $main-color;
     background: $quaternary-color
    }
  }
}

.validation {
  display: block;
  color: red;
  margin-top: -40px;
  text-align: right;
  padding-right: 15px;
  min-height: 20px;
}
