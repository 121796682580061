@use "abstracts" as *;
@use "base" as *;
@use "components" as *;
@use "pages" as *;

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}


/*Vlastní třídy*/
.more-link {
  color: $main-color;
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
  @include borderRounded;
  

  &:hover {
    background-color: $secondary-color;
    color: $quaternary-color;
    text-decoration: none;
  }
}

.tech {
  list-style-type: none;
  padding-inline-start: 0;
  cursor: default;

  @include flexRow;

  li {
    margin: 0 10px 10px 0;
    border-radius: 25px;
    padding: 2px 15px;
    font-size: 0.9rem;
    font-weight: bold;

    @include borderBlue;

    &:hover {
      background-color: $secondary-color;
      color: $tertiary-color;

      @include borderBlack;
    }
  }
}

/*Header*/
#header {
  height: $header-height;
  display: flex;
  background-color: $quaternary-color;
  width: 100%;
  position: fixed;
  padding-top: 50px;
  max-width: 1200px;
  padding-left: 25px;
  padding-right: 25px;

  @include bp-medium {
    height: 90px;
    padding-top: 35px;
  }
}

main {
  margin-top: 150px;
  max-width: 1200px;
  width: 100%;

  @include bp-medium {
    margin-top: 90px;
  }
}

section {
  float: left;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 30px;
      height: auto;
    }
  }

  > header a {
    color: $main-color;
    text-transform: uppercase;
    margin-left: 35px;
  }
}

/*Introduction*/
#introduction {
  height: $full-screen-height;
  min-height: 700px;
  max-height: 780px;
  width: $max-width;
  width: 100%;
  padding-top: 50px;
  
  @include bp-large {
    min-height: auto;
    height: auto;
    padding-bottom: 75px;
  }

  .row {
    display: inline-block;
    width: 100%;
  }
  
  .row-2 {
    text-align: right;
  }
  
  .row-3 {
    text-align: center;
    padding-right: clamp(0px, 10vw, 6.25rem);
  }

  .more-link {
    float: right;
  }
}



/*Projects*/
section#projects article {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: $full-screen-height;
  min-height: 600px;
  max-height: 780px;
  width: $max-width;
  width: 100%;

  .content {
    margin-top: -150px;
    width: 45%;

    @include bp-medium {
      margin-top: 0;
      padding: 25px 0;
      width: 100%;
    }
  }

  .thumbnail {
    margin-top: -150px;
    width: 55%;

    img {
      width: 100%;
      height: auto;
    }

    @include bp-medium {
      margin-top: 50px;
      width: 100%;
    }
  }

  .excerpt {
    padding: 35px 0 0 50px;

    @include bp-medium {
      padding: 25px 0 0 25px;
    }
  }

  .more-link {
    margin-top: 50px;
  }

  &:nth-child(odd) {
    .content {
      padding-left: 50px;
      order: 2;

      @include bp-medium {
        padding-left: 0;
      }
    }

    .thumbnail {
      padding-right: 50px;
      order: 1;

      @include bp-medium {
        padding-right: 0;
      }
    }
  }

  &:nth-child(even) {
    .thumbnail {
      padding-left: 50px;
      order: 2;

      @include bp-medium {
        padding-left: 0;
        order: 1;
      }
    }

    .content {
      padding-right: 50px;
      order: 1;

      @include bp-medium {
        padding-right: 0px;
      }
    }
  }

  @include bp-medium {
    flex-direction: column;
    align-items: flex-start;
    min-height: auto;
    height: auto;
    max-height: unset;
  }
}

/*Services*/
#services {
  height: $full-screen-height;
  max-height: 780px;
  min-height: 780px;
  width: 1200px;
  width: 100%;

  @include bp-large {
    @include nulledHeight;
  }

  > .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 100px;

    @include bp-large {
      padding-top: 0;
    }
  }

  article {
    max-width: 400px;
    width: 33%;
    padding-right: 50px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }

    @include bp-medium {
      width: 50%;
      max-width: unset;
    }

    @include bp-small {
      width: 100%;
    }

    .excerpt {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100% - 130px);

      @include bp-medium {
        padding-top: 25px;
      }
    }

    .more-link-small,
    .more-link {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      margin-top: 50px;
      font-size: 0.75rem;
    }
  }
}

.page-index #services {
  @include bp-large {
    padding-top: 75px;
  }
}

/*Contact*/
#contact {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: $full-screen-height;
  max-height: 780px;
  min-height: 780px;
  width: 1200px;
  width: 100%;
  margin-top: -150px;

  @include bp-large {
    margin-top: 100px;

    @include nulledHeight;
  }

  .contact {
    background-color: #e7e8ec;
    padding: 100px;
    width: 100%;

    @include bp-large {
      padding: 50px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include bp-small {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .email {
    padding-left: 50px;
    padding-top: 50px;

    @include bp-small {
      padding: 35px 0 50px 0;
    }
  }

  @include bp-large {
    padding: 0;
  }

  @include bp-small {
  }
}
