@use "../abstracts" as *;

/*Menu*/
#primary-menu {
  display: flex;
  width: calc(100% - 125px);
  justify-content: center;

  @include bp-medium {
    justify-content: right;
  }

  .icon, button {
  width: 40px;
  height: 40px;
  margin-top: -15px;
  padding: 0;
  border: none;
  
  @include backgroundCenter;
}

button {
  background-size: auto 20px;

  @include backgroundCenter;
  transition: none;
}

.icon {
  background-size: 25px auto;
}

.github {
  background-image: url("/assets/images/github.svg");

  @include bp-medium {
    background-image: url("/assets/images/github-white.svg");
  }
}

.behance {
  background-image: url("/assets/images/behance.svg");

  @include bp-medium {
    background-image: url("/assets/images/behance-white.svg");
  }
}

button {
  background-image: url("/assets/images/hamburger-menu.svg");
}

button.active {
  background-image: url("/assets/images/close.svg");

  @include borderRoundedWhite;
}

#primary-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  #social-media {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a:not(:last-child) {
      margin-right: 15px;
    }

    img {
      width: 20px;
      height: auto;
    }
  }

  @include bp-medium {
    display: none;
  }
}

#primary-menu-container.active {
  @include bp-medium {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $secondary-color;
  }
}

#menu-mobile-toggle {
  display: none;

  @include bp-medium {
    display: block;
    z-index: 2;
  }
}

#container-items {
  width: calc(100% - $logo-width);
  display: flex;
  justify-content: center;

  ul {
    margin: 0;
    padding: 0;

    @include bp-medium {
      @include flexCenter;
    }
  }

  li {
    display: inline-block;
    text-transform: uppercase;

    &:not(&:last-child) {
      margin-right: 50px;

      @include bp-medium {
        margin-right: 0;
      }
    }

    a {
      color: $main-color;

      @include bp-medium {
        color: $quaternary-color;
        font-size: $font-size-xlarge;
      }
    }

    a:hover {
      color: $secondary-color;
      text-decoration: none;

      @include bp-medium {
        color: $main-color;
      }
    }

    @include bp-medium {
      margin-bottom: 35px;
    }
  }
}

}

