@mixin borderRounded {
  background-color: transparent;
  border: 3px solid;
  border-radius: 50%;
  font-weight: bold;
  border-color: #000000;
  
  text-align: center;
  text-decoration: none;
  font-size: 0.9rem;
  text-transform: uppercase;
  @include flexCenterVertical;
}

@mixin borderRoundedWhite {
  border-color: #ffffff;
  color: #ffffff;
  text-shadow: 0 0 0 #ffffff;
}

@mixin fullWidth {
  min-width: 100%;
  width: 100%;
  display: inline-block;
}

@mixin flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenterVertical {
  display: flex;
  justify-content: center;
  align-items: center;

}

@mixin flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin nulledHeight {
  min-height: unset;
  height: unset;
  max-height: unset;
}

@mixin borderBlack {
  border: 3px solid;
  border-color: #000000;
}

@mixin borderBlue {
  border: 3px solid;
  border-color: #013cfe;
}

@mixin backgroundCenter {
  background-color: unset;
  background-repeat: no-repeat;
  background-position: center;
}
/*
@mixin bp-small {
  @media (min-width: 520px) {
    @content;
  }
}

@mixin bp-small {
  @media (max-width: 960px) {
    @content;
  }
}
@mixin bp-medium {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin bp-large {
  @media (max-width: 1200px) {
    @content;
  }
}

@include bp-small {
  :root {
    --fs-small: 1.125rem;
    --fs-medium: 2.3rem;
    --fs-large: 3.5rem;
    --fs-xlarge: 4rem;
  }
}

@include bp-medium {
  :root {
    --fs-small: 1.125rem;
    --fs-medium: 2.3rem;
    --fs-large: 3.5rem;
    --fs-xlarge: 6.5rem;
  }
}
*/
