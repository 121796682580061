@use "../abstracts" as *;

article {
  .content {
    margin: 0 auto;
    padding: 75px 0;
    max-width: 960px;

    @include bp-large {
      padding: 25px 0;
    }
  }

  h1 {
    font-size: $font-size-large;
  }

  img {
    width: 100%;
  }
}
