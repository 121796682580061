@use "../abstracts" as *;

#loading {
  height: calc(100vh - $header-height);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-medium;
  font-family: $font-family-secondary;
  font-weight: 600;
  padding-bottom: 350px;
}
